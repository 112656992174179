import React from "react";
import { TrainerCardSkeleton } from "./trainer-card-skeleton";

export const TrainerGridSkeletons = () => (
  <div className="trainer-grid">
    {new Array(6).fill(0).map((number) => (
      <React.Fragment key={number}>
        <TrainerCardSkeleton />
      </React.Fragment>
    ))}
  </div>
);
